import { ReactNode } from 'react';
import { classNames } from '~/lib';
import { Link, Spinner } from '.';

const variants = {
  primary:
    'border-none text-grey-800 bg-yellow-400 hover:bg-yellow-500 focus:bg-yellow-500 focus:ring-2 focus:ring-yellow-300',
  secondary:
    'border-none text-white bg-grey-800 hover:bg-grey-700 focus:bg-grey-800 focus:ring-2 focus:ring-grey-200',
  outline: 'border-gray-200 text-gray-900 bg-white hover:bg-gray-50',
  'outline-transparent': 'border-gray-200 text-white',
  light: 'border-none text-grey-800 bg-yellow-100',
  'dark-border':
    'bg-dark-grey-800 border-gray-800 text-white rounded border-yellow-500',
  dark: 'bg-dark-grey-800 border-gray-800 text-white rounded',
  white: 'text-grey-800 bg-white font-base',
};

const sizes = {
  small: 'py-1 px-2',
  medium: 'py-3 px-6',
  large: 'py-4 px-8',
};

interface ButtonProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  href?: string;
  isLoading?: boolean;
  type?: 'button' | 'submit';
  size?: keyof typeof sizes;
  variant: keyof typeof variants;
  onClick?: (e?: any) => void;
}

export const Button = ({
  className,
  children,
  disabled,
  href,
  isLoading,
  onClick,
  size = 'medium',
  type = 'button',
  variant,
}: ButtonProps) => {
  if (href) {
    return (
      <Link href={href}>
        <a
          className={classNames(
            isLoading && 'cursor-wait',
            'flex relative justify-center items-center border rounded text-base font-medium transition-colors duration-200 focus:outline-none',
            sizes[size],
            variants[variant],
            className,
          )}
        >
          {children}
          {isLoading && (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-gray-900 rounded bg-opacity-60">
              <Spinner className="w-5 h-6" />
            </div>
          )}
        </a>
      </Link>
    );
  }

  return (
    <button
      type={type}
      className={classNames(
        isLoading && 'cursor-wait',
        'flex relative justify-center items-center border rounded-md text-base font-medium transition-colors duration-200 focus:outline-none',
        sizes[size],
        variants[variant],
        className,
        disabled && 'cursor-not-allowed opacity-30',
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
      {isLoading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-gray-900 rounded bg-opacity-60">
          <Spinner className="w-5 h-6" />
        </div>
      )}
    </button>
  );
};
