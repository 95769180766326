export const getLinkProps = (link: any) => {
  if (typeof link === 'string') {
    return link;
  }

  if (link?.linktype === 'url') {
    return link?.url;
  }

  if (link?.linktype === 'story') {
    if (link?.story?.url === '/') {
      return link?.story?.url;
    }

    if (link?.cached_url === 'home') {
      return '/';
    }

    return !!link?.story?.url
      ? `/${link?.story?.url}`
      : !!link?.story?.fullSlug
      ? `/${link?.story?.fullSlug}`
      : link?.cached_url?.[0] === '/'
      ? link?.cached_url
      : `/${link?.cached_url}`;
  }

  if (link?.linktype === 'email') {
    return `mailto:${link?.email}`;
  }

  return link?.cached_url || '/';
};
