export const Logo = ({ className }: { className?: string }) => (
  <svg
    className={className || 'h-10 w-auto'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 165 32"
  >
    <path d="M0 21.583h1.968V10.189H0v11.394Z" fill="currentColor" />
    <path
      d="M8.785 13.216c-1.236 0-2.228.652-2.764 1.726v-1.498H4.166v8.139h1.855v-3.988c0-1.66.862-2.767 2.098-2.767 1.43 0 1.43 1.318 1.43 2.246v4.509h1.855v-4.558c0-1.22 0-3.808-2.619-3.808Z"
      fill="currentColor"
    />
    <path
      d="M16.298 21.81c2.114 0 3.594-.96 3.594-2.604 0-3.092-5.253-1.839-5.253-3.483 0-.586.618-1.01 1.561-1.01 1.123 0 1.855.636 1.627 1.563h1.805c.342-1.839-1.236-3.06-3.415-3.06-1.968 0-3.48.993-3.48 2.621 0 3.044 5.236 1.872 5.236 3.5 0 .585-.667.976-1.659.976-1.268 0-2.017-.635-1.903-1.644h-1.789c-.13 1.97 1.383 3.142 3.676 3.142Z"
      fill="currentColor"
    />
    <path
      d="M26.172 15.056v-1.612h-2.326V11.28h-1.854v2.165h-1.545v1.612h1.545v3.971c0 .88.13 2.784 2.553 2.784.586 0 1.09-.114 1.448-.293V19.89c-.342.179-.667.293-1.09.293-.894 0-1.057-.505-1.057-1.172v-3.955h2.326Z"
      fill="currentColor"
    />
    <path
      d="M30.835 13.216c-2.114 0-3.708 1.205-3.27 3.012h1.871c-.195-.912.407-1.4 1.383-1.4 1.3 0 1.74.895 1.74 2.132a8.511 8.511 0 0 0-1.854-.212c-2.293 0-3.725.993-3.725 2.637 0 1.416 1.074 2.426 2.798 2.426 1.333 0 2.325-.603 2.927-1.644v1.416h1.708V16.96c0-2.262-1.204-3.744-3.578-3.744Zm-.683 7.114c-.797 0-1.285-.342-1.285-.993 0-.75.65-1.238 1.984-1.238.585 0 1.236.098 1.773.277-.31 1.188-1.285 1.953-2.472 1.953Z"
      fill="currentColor"
    />
    <path
      d="M40.662 13.216c-1.236 0-2.228.652-2.765 1.726v-1.498h-1.854v8.139h1.854v-3.988c0-1.66.862-2.767 2.098-2.767 1.431 0 1.431 1.318 1.431 2.246v4.509h1.854v-4.558c0-1.22 0-3.808-2.618-3.808Z"
      fill="currentColor"
    />
    <path
      d="M49.82 15.056v-1.612h-2.325V11.28h-1.854v2.165h-1.545v1.612h1.545v3.971c0 .88.13 2.784 2.553 2.784.586 0 1.09-.114 1.448-.293V19.89c-.342.179-.667.293-1.09.293-.894 0-1.057-.505-1.057-1.172v-3.955h2.326Z"
      fill="currentColor"
    />
    <path
      d="M57.023 18.643 71.505.279l-5.338 13.248 10.15-1.652-16.66 20.006 7.407-14.9-10.04 1.662Z"
      fill="#FBCC26"
    />
    <path
      d="M88.38 21.925c2.912 0 4.977-1.807 5.351-4.476H91.65c-.309 1.595-1.496 2.75-3.252 2.75-2.196 0-3.741-1.758-3.741-4.215 0-2.377 1.496-4.184 3.724-4.184 1.92 0 3.107 1.303 2.798 3.288h2.033c.471-2.864-1.692-5.013-4.847-5.013-3.35 0-5.741 2.426-5.741 5.909 0 3.402 2.31 5.94 5.757 5.94Z"
      fill="currentColor"
    />
    <path
      d="M98.9 21.925c2.471 0 4.146-1.726 4.146-4.297 0-2.572-1.675-4.297-4.147-4.297-2.488 0-4.147 1.725-4.147 4.297 0 2.571 1.659 4.297 4.147 4.297Zm0-1.628c-1.334 0-2.213-1.058-2.213-2.67 0-1.61.879-2.669 2.212-2.669 1.318 0 2.212 1.058 2.212 2.67 0 1.611-.894 2.67-2.212 2.67Z"
      fill="currentColor"
    />
    <path
      d="M113.915 13.33c-1.22 0-2.18.652-2.651 1.726-.342-1.058-1.204-1.725-2.391-1.725-1.252 0-2.163.716-2.586 1.676v-1.449h-1.854v8.139h1.854v-3.972c0-1.693.829-2.783 1.919-2.783.895 0 1.334.716 1.334 1.774v4.98h1.854V17.71c0-1.66.78-2.734 1.903-2.734.878 0 1.317.7 1.317 1.741v4.98h1.854v-5.175c0-1.986-.878-3.19-2.553-3.19Z"
      fill="currentColor"
    />
    <path
      d="M127.574 13.33c-1.22 0-2.18.652-2.651 1.726-.342-1.058-1.204-1.725-2.391-1.725-1.252 0-2.163.716-2.586 1.676v-1.449h-1.854v8.139h1.854v-3.972c0-1.693.829-2.783 1.919-2.783.895 0 1.334.716 1.334 1.774v4.98h1.854V17.71c0-1.66.78-2.734 1.903-2.734.878 0 1.317.7 1.317 1.741v4.98h1.854v-5.175c0-1.986-.878-3.19-2.553-3.19Z"
      fill="currentColor"
    />
    <path
      d="M137.476 19.125c-.277.749-.992 1.172-1.903 1.172-1.301 0-2.049-.797-2.212-2.149h5.79c.781-2.669-.699-4.817-3.562-4.817-2.569 0-4.196 1.709-4.196 4.297 0 2.588 1.61 4.297 4.147 4.297 2.033 0 3.481-1.09 3.953-2.8h-2.017Zm-1.919-4.232c1.285 0 1.951.765 1.837 1.937h-4c.195-1.253.943-1.937 2.163-1.937Z"
      fill="currentColor"
    />
    <path
      d="M144.966 13.33c-1.009 0-1.789.603-2.228 1.612v-1.384h-1.855v8.139h1.855v-3.988c0-1.644.618-2.734 1.496-2.734.569 0 .927.439.927 1.09 0 .374-.13.814-.407 1.27h1.936c.244-.489.39-1.075.39-1.66 0-1.368-.83-2.344-2.114-2.344Z"
      fill="currentColor"
    />
    <path
      d="M151.947 21.925c2.261 0 3.627-1.433 4.017-3.125h-2c-.342.911-1.025 1.497-2.001 1.497-1.349 0-2.228-1.058-2.228-2.67 0-1.61.862-2.669 2.261-2.669 1.334 0 2 .96 1.691 2.572h1.871c.601-2.474-.976-4.2-3.546-4.2-2.569 0-4.212 1.71-4.212 4.298 0 2.571 1.659 4.297 4.147 4.297Z"
      fill="currentColor"
    />
    <path
      d="M162.983 19.125c-.276.749-.992 1.172-1.903 1.172-1.301 0-2.049-.797-2.211-2.149h5.789c.781-2.669-.699-4.817-3.561-4.817-2.57 0-4.196 1.709-4.196 4.297 0 2.588 1.61 4.297 4.147 4.297 2.033 0 3.48-1.09 3.952-2.8h-2.017Zm-1.919-4.232c1.285 0 1.952.765 1.838 1.937h-4.001c.195-1.253.943-1.937 2.163-1.937Z"
      fill="currentColor"
    />
  </svg>
);
