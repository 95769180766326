export const Zap = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="17"
      height="27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.974 15.686 12.665.234 8.356 11.382l8.194-1.39L3.1 26.823l5.98-12.537-8.106 1.399Z"
        fill="currentColor"
      />
    </svg>
  );
};
