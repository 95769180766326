import { FC } from 'react';
import {
  render,
  MARK_BOLD,
  MARK_LINK,
  MARK_STRIKE,
  NODE_PARAGRAPH,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_OL,
  NODE_UL,
} from 'storyblok-rich-text-react-renderer';
import { classNames, slugify } from '~/lib';
import { Link } from '..';
import { Heading } from '../Heading';
import { ContentImage } from '.';

interface RichTextProps {
  className?: string;
  markResolvers?: any;
  nodeResolvers?: any;
  text: any;
  textClassName?: string;
  darkMode?: boolean;
  component?: string;
}

export const RichText: FC<RichTextProps> = ({
  className,
  markResolvers = {},
  nodeResolvers = {},
  text,
  textClassName,
  darkMode,
  component,
}) => {
  const textBase = component === 'docs_article';

  return (
    <div
      className={classNames(
        'flex flex-col space-y-3 md:space-y-4',
        darkMode ? 'text-white' : 'text-grey-700',
        textBase ? 'text-base' : 'text-lg',
        component === 'page' && 'lg:px-[234px]',
        component === 'customer_story' && 'text-grey-600 !text-xl',
        className,
      )}
    >
      {render(text, {
        markResolvers: {
          [MARK_BOLD]: (children) => (
            <span className="font-bold">{children}</span>
          ),
          [MARK_STRIKE]: (children) => (
            <s className="text-[#D1D5DB]">{children}</s>
          ),
          [MARK_LINK]: (children, props) => {
            const { href, linktype } = props;

            return (
              <Link
                href={`${linktype === 'email' ? 'mailto:' : ''}${href}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <a
                  className={classNames(
                    'underline relative z-10',
                    darkMode ? 'text-yellow-500' : 'text-blue-600',
                  )}
                >
                  {children}
                </a>
              </Link>
            );
          },
          ...markResolvers,
        },
        nodeResolvers: {
          [NODE_HEADING]: (children, { className, level, id, ...rest }) => {
            const heading = !children
              ? ''
              : typeof children[0] === 'string'
              ? children[0]
              : typeof children[0]?.props?.children === 'string'
              ? children[0]?.props?.children
              : typeof children[0]?.props?.children?.props?.children ===
                'string'
              ? children[0]?.props?.children?.props?.children
              : '';

            return heading ? (
              <Heading
                as={`h${level}` as any}
                variant={
                  component === 'customer_story'
                    ? (`h${level}` as any)
                    : (`h${level + 1}` as any)
                }
                className={classNames(
                  'relative z-0',
                  component === 'customer_story' && '!font-normal',
                  textClassName,
                )}
                {...rest}
              >
                <span className={className} id={slugify(heading, 'section')}>
                  {children}
                </span>
              </Heading>
            ) : undefined;
          },
          [NODE_PARAGRAPH]: (children) => (
            <p className={classNames('leading-relaxed', textClassName)}>
              {children}
            </p>
          ),
          [NODE_IMAGE]: (_, props) => (
            <ContentImage content={{ filename: props?.src, alt: props?.alt }} />
          ),
          [NODE_OL]: (children) => (
            <ol className="space-y-2 mt-0 list-decimal pl-5">{children}</ol>
          ),
          [NODE_UL]: (children) => (
            <ul className="space-y-2 mt-0 list-disc pl-5">{children}</ul>
          ),
          ...nodeResolvers,
        },
      })}
    </div>
  );
};
