import { createElement, ReactNode } from 'react';
import { classNames } from '~/lib';

const shared = 'text-gray-900 sm:tracking-tight';

export const headingVariants = {
  h1: `text-6xl sm:text-7xl font-bold ${shared} leading-none`,
  h2: `text-4xl sm:text-6xl font-bold leading-[1.1] ${shared}`,
  h3: `text-4xl sm:text-5xl font-bold leading-tight ${shared}`,
  h4: `text-2xl md:text-3xl font-medium leading-tight ${shared}`,
  h5: `text-2xl font-medium leading-tight ${shared}`,
  h6: `text-sm font-medium uppercase leading-tight tracking-wide text-gray-900`,
};

interface HeadingProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: ReactNode;
  className?: string;
  variant: keyof typeof headingVariants;
  id?: string;
}

export const Heading = ({
  as,
  children,
  className,
  variant,
  ...props
}: HeadingProps) =>
  createElement(
    as || 'h2',
    {
      className: classNames(
        'font-display',
        headingVariants[variant],
        className,
      ),
      ...props,
    },
    children,
  );
