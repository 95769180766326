import { NextSeo, NextSeoProps } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { getCanonical, getDescription, localizeLink } from '~/lib';

interface SeoProps extends NextSeoProps {
  story?: any;
  image?: string;
  ogType?: 'website' | 'article';
  noIndex?: boolean;
}

export const Seo = ({
  story: storyProp,
  title: titleProp,
  description: descriptionProp,
  image: imageProp,
  ogType,
  noIndex,
  ...props
}: SeoProps) => {
  const { asPath, locale, defaultLocale } = useRouter();
  const fallbackPath = asPath?.replace(/(\?|#).*/, '');

  const alternates = [];
  const url = getCanonical(storyProp, locale, fallbackPath);

  // Adds the default and fallback href lang tags
  const defaultSlug = storyProp?.default_full_slug ?? storyProp?.full_slug;
  const defaultHref = `https://instantcommerce.io${localizeLink(
    defaultSlug ?? fallbackPath,
  )}`;
  alternates.push(
    { hrefLang: 'x-default', href: defaultHref },
    { hrefLang: defaultLocale, href: defaultHref },
  );

  // Adds all alternate languages to href lang tags
  storyProp?.translated_slugs?.forEach(({ lang, name, path }) => {
    if (!!name) {
      const altHref = `https://instantcommerce.io/${lang}${localizeLink(path)}`;
      alternates.push({ hrefLang: lang, href: altHref });
    }
  });

  let title = titleProp;
  let description = descriptionProp;
  let image = {
    url: imageProp || '/og-image-1200x630.jpg',
    width: 1200,
    height: 630,
  };

  const story = storyProp?.content || storyProp;

  if (story) {
    if (!title) {
      title = story.seo?.title || story.title;
    }
    if (!description) {
      description = getDescription(story);
    }
    if (
      !imageProp &&
      (story.seo?.og_image ||
        story.image?.filename ||
        story.thumbnail?.filename)
    ) {
      image.url =
        story.seo?.og_image ||
        story.image?.filename ||
        story.thumbnail?.filename;
    }
  }

  title = title
    ? title.includes('Instant Commerce')
      ? title
      : `${title} - Instant Commerce`
    : 'Instant Commerce - Headless commerce made easy';

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        languageAlternates={alternates}
        noindex={story?.noIndex === true || noIndex}
        openGraph={{
          images: [image],
          type: ogType || 'website',
          site_name: 'Instant Commerce',
        }}
        {...props}
      />
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@id': 'https://instantcommerce.io/#website',
              '@type': 'WebSite',
              url: 'https://instantcommerce.io/',
              name: 'Instant Commerce',
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@id': 'https://instantcommerce.io/#webpage',
              '@type': 'WebPage',
              url: 'https://instantcommerce.io/',
              name: 'Instant Commerce',
            }),
          }}
        />
      </Head>
    </>
  );
};
