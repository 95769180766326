import { localizeLink } from './localizeLink';

/**
 * Get the canonical of the current page
 *
 * @param story Storyblok story (should have translated_slugs, full_slug and default_full_slug)
 * @param locale Current locale
 * @param path Fallback path in case story data is missing
 *
 * @returns Canonical URL
 */
export const getCanonical = (story: any, locale: string, path?: string) => {
  const isTranslated = story?.translated_slugs?.some(
    ({ lang, name }) => !!name && lang === locale,
  );

  // Use default slug for canonical if page has not been translated
  const slug = isTranslated
    ? story?.full_slug
    : story?.default_full_slug ?? story?.full_slug;

  return `https://instantcommerce.io${localizeLink(slug ?? path)}`;
};
