import { createElement, cloneElement } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useLocation } from 'react-use';

interface LinkProps extends NextLinkProps {
  children: any;
  className?: string;
  onClick?(e?: any): void;
  rel?: string;
  target?: string;
}

export const Link = ({ children, href, ...props }: LinkProps) => {
  let to = href as string;
  const isExternal = /^https?:/.test(to);

  const { search } = useLocation();

  if (isExternal) {
    return (
      <>
        {!children?.type || children?.type?.displayName === 'FormattedMessage'
          ? createElement((props.as as any) || 'a', {
              children,
              href: to,
              ...(isExternal
                ? { target: '_blank', rel: 'noopener noreferrer' }
                : {}),
              ...props,
            })
          : cloneElement(children, {
              href: to,
              ...(isExternal
                ? { target: '_blank', rel: 'noopener noreferrer' }
                : {}),
              ...props,
            })}
      </>
    );
  }

  return (
    <NextLink href={!!search ? to + search : to} passHref {...props}>
      {!children?.type || children?.type?.displayName === 'FormattedMessage'
        ? createElement((props.as as any) || 'a', {
            children,
            ...props,
          })
        : cloneElement(children)}
    </NextLink>
  );
};
