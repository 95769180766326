import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';

interface ContentImageProps {
  content: any;
  className?: string;
}

export const ContentImage = ({ content, className }: ContentImageProps) => {
  return (
    <div
      className={classNames(
        'relative overflow-hidden',
        content?.rounded_corners && 'shadow-sm rounded-2xl',
        className,
      )}
    >
      <ToolkitImage
        alt={content?.alt || ''}
        src={content?.filename}
        fluid={content?.size ? 1600 : 1200}
        fit="contain"
        showPlaceholder={false}
        className={classNames(
          'w-full max-w-100',
          content?.rounded_corners && 'rounded-lg',
        )}
      />
    </div>
  );
};
