/** Prefix slug with slash and removes trailing slash */
export const localizeLink = (path: string = ''): string => {
  // Prefix with slash and remove trailing slash
  let localized = `${path.startsWith('/') ? '' : '/'}${
    path.endsWith('/') ? path.slice(0, -1) : path
  }`;

  if (localized === '' || localized === '/home' || localized === '/index') {
    return '/';
  }

  // Removed `/guides/` folder prefix
  localized = localized.replace(/^\/guides\//, '/');

  return localized;
};
